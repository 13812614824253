import React, { Fragment } from "react";

import { SEO } from "components/common/SEO";

import { MarkdownRemark } from "types/markdown";

import { graphql, PageProps } from "gatsby";
import { Sidebar, SidebarLink } from "components/layout";

import { Layout, Container } from "components/layout";
import { DocSection } from "components/view/DocSection";
import { groupBy } from "utils";
import { SidebarLinkGroup } from "components/layout/Sidebar";
import { ViewHeader } from "components/view/ViewHeader";
import "./styles.scss";
import { useElementIDNavigation, useScrolled } from "hooks";

interface DocumentationPageProps extends PageProps {
  data: MarkdownRemark;
}

export default function DocumentationPage(props: DocumentationPageProps) {
  const scrolled = useScrolled();
  useElementIDNavigation('.documentation > div > div[class*="DocSection"]');

  const handleScrollTop = () => {
    window.scrollTo({ top: 0 });
  };

  return (
    <Layout>
      <SEO title="Documentation" />
      <ViewHeader />
      <Container noPadding hasSidebar>
        <Sidebar>
          {groupBy(
            props.data.allMarkdownRemark.edges.map((edge) => ({
              ...edge.node,
              ...edge.node.frontmatter,
              parent:
                edge.node.frontmatter.parent || edge.node.frontmatter.path,
            })),
            ["parent"]
          ).map((group) => (
            <Fragment key={group.parent}>
              <SidebarLink
                to={`/documentation#${group.parent}`}
                active={props.location.hash.includes(`#${group.parent}`)}
              >
                {group.parent}
              </SidebarLink>
              {group.rows.length > 1 && (
                <SidebarLinkGroup
                  open={props.location.hash.includes(`#${group.parent}`)}
                >
                  {group.rows.map((row) => (
                    <SidebarLink
                      key={row.path}
                      to={`/documentation#${row.path}`}
                      active={props.location.hash === `#${row.path}`}
                    >
                      {row.path.split("-")[1]}
                    </SidebarLink>
                  ))}
                </SidebarLinkGroup>
              )}
            </Fragment>
          ))}
        </Sidebar>
        <Container className="documentation">
          {props.data.allMarkdownRemark.edges.map(({ node }) => (
            <DocSection
              key={node.frontmatter.path}
              id={node.frontmatter.path}
              html={node.html}
            />
          ))}
        </Container>

        <a
          onClick={handleScrollTop}
          className={["topButton", scrolled ? "show" : ""].join(" ")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z" />
          </svg>
        </a>
      </Container>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 2000
      sort: { order: ASC, fields: [frontmatter___index] }
    ) {
      edges {
        node {
          frontmatter {
            index
            path
            parent
          }
          html
        }
      }
    }
  }
`;
